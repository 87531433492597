<template>
  <div class="app-page">
    <div class="page-header">
      <div class="page-header-top">
        <div class="left">
          <img class="logo" src="@/assets/logo.png" alt="" />
          <h2>天正IMartrix工业互联网平台</h2>
        </div>
        <div v-if="!signed">
          <el-link
            @click="
              () => {
                $router.push('/login')
              }
            "
            >登录</el-link
          >
          |
          <el-link
            @click="
              () => {
                $router.push('/register')
              }
            "
            type="primary"
            >免费注册</el-link
          >
        </div>
        <div v-else>
          <span
            style="font-size: 14px; margin-right: 10px; vertical-align: -2px"
          >
            {{ userInfo.name }}
          </span>
          <el-link type="primary" @click="close">退出</el-link>
        </div>
      </div>
      <menus :list="menus" />
    </div>
    <div class="page-content">
      <iframe :src="activePage" frameborder="0" ref="content"></iframe>
    </div>
  </div>
</template>
<script>
import { constantRoutes } from '@/router'
import { getToken, removeToken } from '@/utils/auth'
import Menus from './components/nav'
export default {
  components: { Menus },
  data() {
    return {
      menus: constantRoutes,
      activeIndex: '0',
      userInfo: null
    }
  },
  created() {
    let userInfo = window.localStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    }
    if (this.$route.meta.redirect) {
      window.location.href = this.$route.meta.redirect
    }
  },
  computed: {
    activePage() {
      return this.$route.meta.src
    },
    signed() {
      return getToken() ? true : false
    }
  },
  mounted() {
    this.appendFooter()
  },
  watch: {
    activePage() {
      this.$nextTick(() => {
        this.appendFooter()
      })
    }
  },
  methods: {
    close() {
      removeToken()
      this.$router.push('login')
    },
    appendFooter() {
      if (this.$route.meta.appendFooter == false) {
        return
      }
      const _this = this
      const iframe = this.$refs.content
      iframe.onload = function () {
        var doc = iframe.contentDocument
        var ele = doc.createElement('div')
        ele.innerHTML = `
          <div style="width: 100%;height: 300px;background: rgba(26, 29, 36, 1)">
            <div style="height: 300px;width: 1000px;margin: 0 auto;display: flex;align-items: center;">
              <img
                src="https://xinhui2017.oss-cn-hangzhou.aliyuncs.com/analysisFile/footer.png"
                alt=""
                style="height: 140px; width: 260px"
              />
              <div style="flex: 1 0 auto;color: rgba(255, 255, 255, 1);margin-left: 40px;">
                <ul id="footerNav" style="list-style: none;width: 100%;display: flex;justify-content: space-between;font-size: 14px;">
                  <li data-path="/">首页</li>
                  <li data-path="/node">边缘节点</li>
                  <li data-path="/app/visualization">工业应用</li>
                  <li data-path="/appMarket">工业APP市场</li>
                  <li data-path="/support">金融支持</li>
                  <li data-path="/bigData/test">工业大数据</li>
                  <li data-path="/plant/api">平台间调用</li>
                  <li data-path="/paas">PAAS服务</li>
                  <li data-path="/dataManagement">工业数据管理</li>
                  <li data-path="/model">工业模型</li>
                </ul>
                <div style="text-align: center; margin: 60px 0 20px">
                  copyright 2019-2020 常州天正工业发展股份有限公司
                </div>
                <div style="display: flex; justify-content: space-between">
                  <span>常州天正工业发展股份有限公司 </span>
                  <span>咨询热线：0519-83998666 </span>
                  <a style="text-decoration:none;color:#fff;" target="_blank"  href="https://beian.miit.gov.cn/#/Integrated/index">备案：苏ICP备14059190号-5</a>
                </div>
              </div>
            </div>
          </div>
          `
        doc.body.appendChild(ele)
        doc.querySelectorAll('#footerNav>li').forEach((node) => {
          node.onclick = function () {
            if (this.dataset.path) {
              _this.$router.push(this.dataset.path)
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" >
.app-page {
  height: 100%;
  min-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  > .page-header {
    width: 1000px;
    margin: 10px auto;
    .el-menu--horizontal {
      border: 0px;
      > .el-submenu .el-submenu__title,
      > .el-menu-item {
        padding: 0 15px;
        height: 45px;
        line-height: 45px;
      }
    }
    > .page-header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
      }
      .logo {
        margin-right: 20px;
        height: 40px;
        width: 40px;
      }
      h2 {
        color: rgba(56, 56, 56, 1);
        font-size: 24px;
        margin: 0;
      }
    }
  }
  > .page-content {
    flex: 1;
    height: 100%;
    overflow: hidden;
    > iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>