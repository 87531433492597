
<script>
// <el-menu mode="horizontal" router>
//       <component
//         v-for="(item, index) in menus"
//         :is="item.children ? 'el-submenu' : 'el-menu-item'"
//         :index="item.path"
//         :key="index"
//       >
//         <template slot="title">
//           {{ item.meta.title }}
//         </template>
//         <component
//           v-for="(child, index_1) in item.children || []"
//           :is="child.children ? 'el-submenu' : 'el-menu-item'"
//           :index="item.path + '/' + child.path"
//           :key="index + '-' + index_1"
//         >
//           <template slot="title"> {{ child.meta.title }}</template>
//           <el-menu-item
//             v-for="(child_2, index_2) in child.children || []"
//             :index="item.path + '/' + child.path + '/' + child_2.path"
//             :key="index + '-' + index_1 + '-' + index_2"
//           >
//             {{ child_2.meta.title }}
//           </el-menu-item>
//         </component>
//       </component>
//     </el-menu>
export default {
  props: { list: Array },
  render(h) {
    return h(
      'el-menu',
      { props: { mode: 'horizontal', router: true } },
      this.renderChild(h, this.list)
    )
  },
  methods: {
    renderChild(h, list, parentPath) {
      if (list && Array.isArray(list)) {
        return list.map((item) => {
          var path = parentPath ? parentPath + '/' + item.path : item.path
          if (item.children) {
            return h(
              'el-submenu',
              {
                props: { index: path, key: path }
              },
              [
                h('template', { slot: 'title' }, item.meta.title),
                this.renderChild(h, item.children, path)
              ]
            )
          } else {
            return h(
              'el-menu-item',
              {
                props: item.meta.href
                  ? { key: path }
                  : { index: path, key: path }
              },
              [
                h(
                  'template',
                  { slot: 'default' },
                  item.meta.href
                    ? [
                        h(
                          'el-link',
                          {
                            props: {
                              href: item.meta.href
                            },
                            domProps: {
                              target: '_blank'
                            }
                          },
                          item.meta.title
                        )
                      ]
                    : [h('span', {}, item.meta.title)]
                )
              ]
            )
          }
        })
      } else {
        return null
      }
    }
  }
}
</script>
